import Vue from 'vue'
import App from './App.vue'
import router from './router'


import 'view-design/dist/styles/iview.css'; //导入样式
import {
  Button,
  Table,
  Icon,
  Carousel,
  CarouselItem,
} from 'view-design';

Vue.component('Button', Button);
Vue.component('Table', Table);
Vue.component('Icon', Icon);
Vue.component('Carousel', Carousel);
Vue.component('CarouselItem', CarouselItem);



import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'MBssT58lSDNv35Ioo56TnPHYPtGpLv4o'
})


import VueParticles from 'vue-particles'
Vue.use(VueParticles)

import animated from 'animate.css'
Vue.use(animated)

import VueAwesomeSwiper from 'vue-awesome-swiper'



Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')