import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
    path: '/index',
    name: 'Index',
    component: () => import('../views/index/Index.vue')
  },
  {
    path: '/index_m',
    name: 'Index_m',
    component: () => import('../views/index/IndexM.vue')
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import('../views/team/Team.vue')
  },
  {
    path: '/business',
    name: 'Susiness',
    component: () => import('../views/business/Business.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/contact/Contact.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  routes
})

//处理路由重复报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router