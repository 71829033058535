<template>
  <div class="page">
    <div class="left">
      <img class="logo-1"
        src="../assets/img/components/01.png"
        @click="home" />
    </div>
    <div class="nav">
      <div class="item"
        v-for="(item, index) in navList"
        :class="{ 'router-link-active': index === curIndex }"
        :key="item.index"
        @click="nav(index, item.path)"
        router-link>
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      curIndex: 0,
      navList: [
        { name: "首页", path: "index" },
        { name: "服务团队", path: "team" },
        // { name: "业务与案例", path: "business" },
        { name: "联系我们", path: "contact" },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    home() {
      this.$router.push({
        path: "/index",
      });
      this.curIndex = 0;
    },
    nav(i, url) {
      this.curIndex = i;
      this.$router.push({
        path: url,
      });
    },
  },
  created() { },
  mounted() { },
};
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  display: flex;
  z-index: 101;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  position: fixed;
  box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
}

.left {
  height: 68px;
  display: flex;
  align-items: center;
  .logo-1 {
    cursor: pointer;
    width: 80px;
    height: 80px;
    margin-left: 160px;
  }
}

.nav {
  width: 492px;
  height: 68px;
  margin-right: 160px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .item {
    // background: yellow;
    height: 28px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    a {
      text-decoration: none;
      color: #000014;
    }
  }
}
.router-link-active {
  font-weight: 500;
  color: #ef8200;
  border-bottom: 2px solid #ef8200;
}
</style>
