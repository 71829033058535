<template>
  <div>
    <div class="foot">
      <div class="left">
        <div class="title">CONTACT</div>
        <div class="contact">
          <div class="text">公司：壹亩地供应链管理有限公司</div>
          <div class="text">电话：400-169-3100</div>
          <!-- <div class="text">邮箱：2535192665@qq.com</div> -->
          <div class="text">
            地址：湖南省长沙市天心区友谊路413号运成大厦16楼
          </div>
        </div>
      </div>
      <div class="cen">
        <div class="title">公司旗下产品</div>
        <div class="product">
          <div class="item" v-for="item in product" :key="item.index">
            <a href="http://h5.dd100.com.cn/">{{ item }}</a>
          </div>
        </div>
        <div class="copyright">
          <a href="https://beian.miit.gov.cn/"> 湘ICP备2021015794号-2</a> |
          <a href="https://dd100.com.cn/"
            >Copyright © 2022 壹亩地供应链管理有限公司</a
          >
        </div>
      </div>
      <div class="right">
        <div class="title">小程序</div>
        <img class="img" src="../assets/img/components/dd100.jpg" />
      </div>
      <div class="right">
        <div class="title">服务号</div>
        <img class="img" src="../assets/img/components/fwh.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      product: ["地道集市", "地道智农", "地道乡村", "地道农圈", "地道农家"],
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.foot {
  height: 210px;
  background: #373f5e;
  color: #fff;
  display: flex;
  justify-content: space-around;
  .title {
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin-top: 28px;
  }
  .left {
    width: 220px;
    height: 210px;
    .contact {
      margin-top: 28px;
      .text {
        color: #8e93a4;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .cen {
    width: 620px;
    height: 210px;
    .product {
      margin-top: 25px;
      height: 38px;
      width: 620px;
      border-bottom: 1px solid #8e93a4;
      display: flex;
      justify-content: space-around;
      .item {
        color: #8e93a4;
        font-size: 14px;
      }
      .item:hover {
        color: #ef8200;
      }
    }
    .copyright {
      margin-top: 28px;
      font-size: 14px;
      color: #8e93a4;
      text-align: center;
    }
  }
  .right {
    width: 120px;
    height: 210px;
    text-align: center;
    .img {
      margin-top: 12px;
      width: 116px;
      height: 116px;
      border-radius: 6px;
    }
  }
}
a {
  color: #8e93a4;
}
a:hover {
  color: blue;
}
</style>
