<template>
  <div id="app">
    <!-- <vue-particles
      class="particles"
      color="#c0e5fe"
      :particleOpacity="0.7"
      :particlesNumber="100"
      shapeType="circle"
      :particleSize="4"
      linesColor="#c0e5fe"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="bubble"
    >
    </vue-particles> -->
    <div>
      <pageHead v-if="!isMobile"></pageHead>
      <router-view />
      <pageFoot v-if="!isMobile"></pageFoot>
    </div>
  </div>
</template>

<script>
import pageHead from "./components/PageHead";
import pageFoot from "./components/PageFoot";
export default {
  data() {
    return {
      isMobile: false,
      // url: "https://m.tqukeji.com",
    };
  },
  components: {
    pageHead,
    pageFoot,
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag) {
        this.isMobile = true;
        // this.$router.push({ path: "index_m" });
        window.location.href = this.url;
      } else {
        this.isMobile = false;
        this.$router.push({ path: "index" });
      }
      // console.log(this.isMobile);
    },
  },
  created() {
    this._isMobile();
  },
};
</script>

<style lang="less">
#app {
  position: relative;
}
#particles-js {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -99;
  pointer-events: none;
}
</style>
